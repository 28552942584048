import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import CarouselNavigation from '@components/carousels/carouselNavigation';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import { ProductPrice } from '@components/product/productPrice';
import { PageLink } from '@components/ui/links';

const CarouselCard = ({
  title,
  price,
  comparePrice,
  productDetail,
  cardImage,
  slug,
}) => {
  return (
    <Link
      to={`/products/${slug && slug.current}`}
      className='flex-shrink-0 w-full lg:w-1/2'
    >
      {/* Image */}
      <div className='aspect-w-6 aspect-h-4 relative rounded-lg overflow-hidden'>
        {cardImage && (
          <SanityImage
            image={cardImage}
            className='absolute inset-0 object-cover'
          />
        )}
      </div>
      {/* Details */}
      <div className='text-center mt-3 grid gap-y-2'>
        <h3 className='font-medium blockH4'>{title}</h3>
        {!!productDetail && <h4 className='blockH5'>{productDetail}</h4>}
        <div>
          <div className='btn inline-flex gap-x-2'>
            <ProductPrice price={price} comparePrice={comparePrice} />
            <span>Buy Now</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

const CollectionCarousel = ({
  config,
  subtitle,
  title,
  shopAllLink,
  products,
}) => {
  const [viewportRef, embla] = useEmblaCarousel({
    dragFree: true,
    align: 'start',
    containScroll: true,
  });
  const breakpoints = useBreakpoint();

  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);

  const scrollPrev = React.useCallback(
    () => embla && embla.scrollPrev(),
    [embla]
  );

  const scrollNext = React.useCallback(
    () => embla && embla.scrollNext(),
    [embla]
  );

  const onSelect = React.useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  React.useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <ModuleWrapper {...config}>
      <div className='overflow-hidden py-2 px-gutter'>
        {/* Title Pane */}
        <div className='text-center pb-10 lg:hidden'>
          <h4 className='subTwo'>{subtitle}</h4>
          <h3 className='blockH1 mb-half'>{title}</h3>
          <PageLink {...shopAllLink} className='btn' />
        </div>
        <div className='relative group'>
          <div ref={viewportRef} className='w-full lg:relative lg:z-0'>
            <div className='flex gap-x-4'>
              {breakpoints.md && (
                <div className='text-center hidden lg:text-left lg:flex lg:flex-col pr-24 lg:flex-shrink-0 lg:items-start lg:justify-center '>
                  <h4 className='subOne'>{subtitle}</h4>
                  <h3 className='blockH1 mb-6'>{title}</h3>
                  <PageLink {...shopAllLink} className='btn' />
                </div>
              )}
              {products.map((item, index) => (
                <CarouselCard key={index} {...item} />
              ))}
            </div>
          </div>
          <CarouselNavigation
            prevBtnEnabled={prevBtnEnabled}
            nextBtnEnabled={nextBtnEnabled}
            scrollPrev={scrollPrev}
            scrollNext={scrollNext}
          />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default CollectionCarousel;
